import React from "react"
import { Helmet } from "react-helmet"
import { Recipe } from "rily"
import favicon from "../../static/images/favicon.ico"
import { fetchSeoMetadata } from "../../hooks/fetchSeoMetadata"
import { slug } from "../../utils/slug"
import { Article } from "../../types/generatedTypes"

interface Props {
  title?: string
  description?: string
  image?: string
  pagePath?: string
  twitterUsername?: string
  recipe?: Recipe
  article?: Article
}

const SEO: React.FC<Props> = ({
  title,
  description,
  image,
  pagePath,
  recipe,
  article,
}) => {
  const { site } = fetchSeoMetadata()

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultImage,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    siteUrl: pagePath ? `${siteUrl}/${pagePath}` : siteUrl,
  }

  const addRecipeJsonLd = recipe => {
    const addImages = () => {
      let imageJson = []
      recipe.images.forEach(image => {
        imageJson.push(`"${image}"`)
      })
      return imageJson
    }

    const addIngredients = () => {
      let ingredientJson = []
      recipe.ingredients.forEach(ingredient => {
        ingredientJson.push(
          `"${ingredient.quantity} ${ingredient.unit} ${ingredient.data.name}"`
        )
      })
      return ingredientJson
    }

    const addInstructions = () => {
      let instructionJson = []
      recipe.preparation.forEach(instruction => {
        instructionJson.push(
          `{ "@type": "HowToStep", "text": "${instruction}" }`
        )
      })
      return instructionJson
    }
    return (
      <script type="application/ld+json">
        {`
  {
    "@context": "https://schema.org",
    "@type": "Recipe",
    "author": {
      "@type": "Person",
      "name": "${recipe.author.name}",
      "sameAs": "${siteUrl}/${slug(recipe.author.name)}"
    },
    "name": "${recipe.title}",
    "recipeCuisine": "${recipe.cuisine}",
    "recipeCategory": "${recipe.cuisine}",
    "cookTime": "PT${recipe.cookTime}M",
    "description": "${recipe.description}",
    "thumbnailUrl": "${recipe.images[0]}",
    "image": [${addImages()}],
    "recipeIngredient": [${addIngredients()}],
    "recipeInstructions": [${addInstructions()}], 
    "recipeYield": "${recipe.servingSize || ""}",
    "publisher": {
      "@type": "Organization",
      "name": "Rily",
      "url": "${siteUrl}",
      "logo": {
        "@type": "ImageObject",
        "url": "https://res.cloudinary.com/heat-supply/image/upload/c_thumb,w_200,g_face/v1632937790/rily_thumbnail.jpg",
        "width": "200",
        "height": "142"
      }
    }

  }
`}
      </script>
    )
  }

  const addArticleJsonLd = article => {
    return (
      <script type="application/ld+json">
        {`
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "author": {
      "@type": "Person",
      "name": "${article.author.name}",
      "sameAs": "${siteUrl}/author/${slug(article.author.name)}"
    },
    "name": "${article.title.replace(/^"|"$/g, "")}",
    "headline": "${article.title.replace(/^"|"$/g, "")}",
    "description": "${article.subtitle.replace(/^"|"$/g, "")}",
    "thumbnailUrl": "${article.header.url}",
    "image": "${article.header.url}",
    "publisher": {
      "@type": "Organization",
      "name": "Rily",
      "url": "${siteUrl}",
      "logo": {
        "@type": "ImageObject",
        "url": "https://res.cloudinary.com/heat-supply/image/upload/c_thumb,w_200,g_face/v1632937790/rily_thumbnail.jpg",
        "width": "200",
        "height": "142"
      }
    },
    "datePublished": "${article.created_at}",
    "dateModified": "${article.updated_at}"
  }
`}
      </script>
    )
  }

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <link rel="icon" href={favicon} />
        <link rel="canonical" href={seo.siteUrl} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.siteUrl && <meta property="og:url" content={seo.siteUrl} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        {recipe && addRecipeJsonLd(recipe)}
        {article && addArticleJsonLd(article)}
      </Helmet>
    </>
  )
}

export default SEO
