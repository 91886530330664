import React, { useEffect, useState } from "react"
import CookieBanner from "./components/cookieBanner"
import { ThemeProvider } from "styled-components"
import { theme } from "./styles/theme"
import { useCookie } from "./hooks/useCookie"
import SEO from "./components/seo"
import { ApolloProvider } from "@apollo/client"
import client from "./apollo/client"
import { LocationProvider } from "@reach/router"
import { ServerStyleSheet, StyleSheetManager } from "styled-components"
import Layout from "./components/layout"
import { GlobalStyle } from "./styles/globalStyles"
import { event, selectContentevent, selectItemEvent } from "./utils/analytics"
import { Provider, getInitialState } from "./context"

const OuterWrapper = ({ element, props }) => {
  const isBrowser = typeof window !== "undefined"

  const [cookie, setCookie] = useCookie("accept_terms")
  const [showBanner, setShowBanner] = useState(false)

  // pageContext only available on SSR here, we use to build proper meta in the HTML
  // on the live site, the component provides it's own SEO metadata
  let pageContext = props?.pageContext || { title: "", image: "" }
  let pagePath = isBrowser
    ? window.location.pathname
    : props?.location?.pathname

  const hideBanner = () => setShowBanner(false)
  const acceptCookies = () => {
    setCookie("true")
    hideBanner()
  }

  useEffect(() => {
    console.log(cookie)
    if (!cookie) {
      setShowBanner(true)
    }
  })

  useEffect(() => {
    const handleEvent = (e: any) => {
      if (e.detail.content_type) {
        selectContentevent(e.detail)
        return
      }

      if (e.detail.item_id) {
        selectItemEvent(e.detail)
        return
      }

      event(e.detail)
    }

    document.addEventListener("GoogleAnalytics", handleEvent)

    return function () {
      document.removeEventListener("GoogleAnalytics", handleEvent)
    }
  }, [])

  return (
    <LocationProvider>
      <Provider value={getInitialState()}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <GlobalStyle />
            <SEO
              title={pageContext?.title}
              image={pageContext?.image}
              pagePath={pagePath}
            />
            <Layout>{element}</Layout>
            {showBanner && (
              <CookieBanner
                hideBanner={hideBanner}
                acceptCookies={acceptCookies}
              />
            )}
          </ApolloProvider>
        </ThemeProvider>
      </Provider>
    </LocationProvider>
  )
}

export const getWrapper =
  (sheet: ServerStyleSheet) =>
  ({ element, props, location }) => {
    if (sheet) {
      return (
        <StyleSheetManager sheet={sheet.instance}>
          <OuterWrapper element={element} props={{ ...props, ...location }} />
        </StyleSheetManager>
      )
    }

    return <OuterWrapper element={element} props={{ ...props, ...location }} />
  }
