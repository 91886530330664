// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about_us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-articles-all-index-tsx": () => import("./../../../src/pages/articles/all/index.tsx" /* webpackChunkName: "component---src-pages-articles-all-index-tsx" */),
  "component---src-pages-articles-article-tsx": () => import("./../../../src/pages/articles/article.tsx" /* webpackChunkName: "component---src-pages-articles-article-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-preview-all-index-tsx": () => import("./../../../src/pages/articles/preview/all/index.tsx" /* webpackChunkName: "component---src-pages-articles-preview-all-index-tsx" */),
  "component---src-pages-articles-preview-index-tsx": () => import("./../../../src/pages/articles/preview/index.tsx" /* webpackChunkName: "component---src-pages-articles-preview-index-tsx" */),
  "component---src-pages-author-index-tsx": () => import("./../../../src/pages/author/index.tsx" /* webpackChunkName: "component---src-pages-author-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-recipe-index-tsx": () => import("./../../../src/pages/recipe/index.tsx" /* webpackChunkName: "component---src-pages-recipe-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */)
}

