export const localizedStrings = {
  "about.intro":
    "We are a new kind of online recipe website geared toward diets, allergies, illnesses and conditions. We have a hand-crafted filtering system of over 900 ingredients and over 40 conditions, —thoughtfully put together by our team of nutritionists.",
  "about.left.heading": "Filling the gap between recipes and nutrition.",
  "about.meet.our.team": "Meet our team.",
  "about.p1":
    "Our Founder Meredith Holbrook was inspired when her father was diagnosed with cancer in 2019. While researching the best food options for her fathers diagnosis Meredith encountered a large web of contradictory information spread across many sources.",
  "about.p2":
    "Rily was born to fill the gap that she found in the online information. A place where the family or person affected by an illness could find clear information and delicious recipes to help their diagnoses and really enjoy what they are eating and cooking from licenced nutritionists.",
  "about.p3":
    "In the chaos of illnesses, there is one thing a person can control and that is nutrition.",
  "button.sort_by_reccomended": "Sort by Recommended",
  "button.sort_by_date": "Sort by Date",
  "button.sort_by_title": "Sort by Title",
  "button.sort_by_latest": "Sort by Latest",
  "button.sort_by_oldest": "Sort by Oldest",
  "button.sort_by_cook_time": "Sort by Cook Time",
  "button.quickview": "Quickview",
  "button.submit": "Submit",
  "button.submit.success": "Success",
  "button.view.recipe": "View Recipe",
  "cookie.banner":
    "You are entering a Canadian website and are agreeing to our terms of service. Our sites use cookies to improve your experience and enable certain functionality.",
  "footer.link.recipes": "Recipes",
  "footer.link.about_us": "About Us",
  "footer.link.instagram": "Instagram",
  "footer.link.facebook": "Facebook",
  "footer.link.twitter": "Twitter",
  "footer.link.youtube": "YouTube",
  "footer.link.terms": "Terms of Use",
  "footer.link.privacy": "Privacy",
  "footer.subscribe.tos":
    'By clicking "submit", you’re consenting to our email newsletter with cooking content and information on products. You may withdraw your consent at any time.',
  "form.email_address": "Email Address",
  "ingredientList.serves": "Serves",
  "ingredientList.title": "Ingredients",
  "not_found.intro": "Page not found",
  "not_found.goto": "Go to main page",
  "quickview.view_instructions": "View Instructions",
  "quickview.ingredients": "Ingredients",
  "quickview.recipe": "Quickview Recipe",
  "quickview.time": "Time",
  "recipe.instructions": "Instructions",
  "recipe_list.header.text": "See what others are cooking right now",
  "recipe.similar_recipes": "Similar Recipes",
}
