import { graphql, useStaticQuery } from "gatsby"

export const fetchSeoMetadata = () => {
  return useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          defaultImage: image
          titleTemplate
          defaultDescription: description
          siteUrl
          twitterUsername
        }
      }
    }
  `)
}
