import styled from "styled-components"

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.03em;
  color: ${props => props.theme.colors.black};
`

export const CaptionText = styled(Text)`
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
  &:hover {
    text-decoration: none;
  }
`

export const SubCaptionText = styled(Text)`
  font-size: 12px;
  line-height: 13.37px;
`

export const HeadingText = styled(Text)`
  font-size: 24px;
  line-height: 27.46px;
`
