import React from "react"
import styled from "styled-components"
import { getLocalizedString } from "../../utils/localization"
import { DarkButton } from "../buttons/darkButton"
import { CaptionText } from "../typography/paragraph"

const Banner = styled.div`
  position: fixed;
  bottom: 0;

  width: calc(100vw - 40px);
  padding: 10px 20px;

  background-color: ${props => props.theme.colors.floralWhite};
  color: ${props => props.theme.colors.black};

  border-top: 1px solid ${props => props.theme.colors.yellow};
  z-index: 10;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-evenly;
  }
`

const BannerText = styled(CaptionText)`
  font-size: 14px;
  width: 100%;
  align-self: center;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin: 0;
  }
`

const ButtonContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const AcceptButton = styled(DarkButton)`
  background: ${props => props.theme.colors.yellow};
  margin-right: 10px;
`

const DeclineButton = styled(DarkButton)`
  background: ${props => props.theme.colors.floralWhite};
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.platinum};
`

interface Props {
  hideBanner: Function
  acceptCookies: Function
}

const CookieBanner: React.FC<Props> = props => {
  return (
    <Banner>
      <BannerText>{getLocalizedString("cookie.banner")}</BannerText>
      <ButtonContainer>
        <AcceptButton
          type="submit"
          onClick={() => {
            props.acceptCookies()
          }}
        >
          Accept
        </AcceptButton>
        <DeclineButton
          type="submit"
          onClick={() => {
            props.hideBanner()
          }}
        >
          Dismiss
        </DeclineButton>
      </ButtonContainer>
    </Banner>
  )
}

export default CookieBanner
