import { getWrapper as wrapper } from "./src/root-wrapper"

export const wrapRootElement = wrapper(null)

// Enforces SPA no refresh-on-route-change behaviour.
// If we're on the same page and just adding params, don't refresh or change scroll position
export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  if (
    prevRouterProps?.location?.pathname === routerProps.location.pathname &&
    !routerProps.location.state?.shouldScroll
  ) {
    return false
  }

  return true
}
