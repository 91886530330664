import styled from "styled-components"

export const DarkButton = styled.button`
  background-color: ${({ theme }) => theme.colors.feldgrau};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.neueHaasGrotesk};
  padding: 5px 10px;
  border: none;
  border-radius: 14px;
  font-size: 14px;
  line-height: 16.02px;
  width: fit-content;
  letter-spacing: 0.03em;
  cursor: pointer;
`
