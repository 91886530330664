declare global {
  interface Window {
    gtag?: Function
  }
}

export const event = ({ action, category, label, value }) => {
  sendEvent("unregistered_event", { action, category, label, value })
}

export const selectItemEvent = details => {
  sendEvent("select_item", details)
}

export const selectContentevent = details => {
  sendEvent("select_content", details)
}

export const joinGroupEvent = (group_id: string) => {
  sendEvent("join_group", { group_id })
}

const sendEvent = (name: string, eventData: Record<any, any>) => {
  if (typeof window !== "undefined") {
    const fallback = () => {
      return
    }
    window.gtag = window.gtag || fallback
    window.gtag("event", name, eventData)
  }
}
