import { FilterOption } from "@rily/components/lib/src/types/filters"
import React, { createContext, useState } from "react"

export const stringParams = ["sort", "page"] as const

export const arrayParams = [
  "ingredients",
  "diets",
  "conditions",
  "constraints",
] as const

export const validParams = [...stringParams, ...arrayParams] as const

export type RecipeParams = Record<typeof validParams[number], any>

export const getInitialState = () => {
  const [params, setParams] = useState<Partial<RecipeParams>>({})
  const [constraints, setConstraints] = useState<FilterOption[]>([])

  return {
    params,
    setParams,
    constraints,
    setConstraints,
  }
}

export const AppContext = createContext({
  params: null,
  setParams: null,
  constraints: null,
  setConstraints: null,
})
export const Provider = AppContext.Provider
