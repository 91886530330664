import { DefaultTheme } from "styled-components"

export type ExtendedTheme = DefaultTheme & {
  [key: string]: any
}

export const theme: ExtendedTheme = {
  colors: {
    yellow: "#FFC700",
    white: "#FFFFFF",
    gray: "#A6A39B",
    green: "#727963",
    darkGreen: "#4F5041",
    black: "#000000",
    floralWhite: "#FBF8F2",
    platinum: "#E5E5E5",
    feldgrau: "#4F5041",
  },
  fonts: {
    neueHaasGrotesk: `"Neue Haas Grotesk Display", sans-serif`,
    tiempos: `"Tiempos Fine", serif`,
  },
} as const
