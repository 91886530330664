import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import fetch from "isomorphic-fetch"
const { GATSBY_EDITORIAL_URL } = process.env

const httpLink = createHttpLink({
  uri: `${GATSBY_EDITORIAL_URL}`,
  fetch,
})
const link = ApolloLink.from([httpLink])

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

export default client
